import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Environment, EnvironmentService } from '@util/environment';
import { Brand } from './brand.type';
import { BrandingColorMap } from './branding-color-map.interface';
import { BRAND_HOSTS_MAP } from './branding.constants';
import { Branding } from './branding.interface';
import { BRANDING_COLOR_MAPS } from './brands.constants';
import { ABNIC_BRANDING } from './brands/abnic/abnic.branding';
import { ASPIRE_BRANDING } from './brands/aspire/aspire.branding';
import { MTIBA_BRANDING } from './brands/mtiba/mtiba.branding';
import { WindowService } from './window';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  constructor(
    private readonly environment: EnvironmentService,
    private readonly title: Title,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  initBranding(): void {
    this.document.body.classList.add('ui-branding--' + this.environment.environment.branding);
    this.title.setTitle(this.branding().pageTitle);
  }

  brand(): Environment['branding'] {
    return this.environment.environment.branding;
  }

  branding(): Branding {
    return this.getBaseBrandingByCountry();
  }

  brandColors(): BrandingColorMap {
    if (!BRANDING_COLOR_MAPS[this.getBrandName()]) {
      throw new Error(`Brand color map not found for brand: ${this.getBrandName()}`);
    }
    return BRANDING_COLOR_MAPS[this.getBrandName()];
  }

  private getBrandName(): Brand {
    const host: string = WindowService.getWindow().location.host.toLowerCase();

    let brand: Brand = this.brand() || 'aspire';

    for (const key in BRAND_HOSTS_MAP) {
      if (host.includes(key)) {
        brand = BRAND_HOSTS_MAP[key];
        break;
      }
    }

    return brand;
  }

  private getBaseBrandingByCountry(): Branding {
    switch (this.getBrandName()) {
      case 'mtiba':
        return MTIBA_BRANDING;

      case 'aspire':
        return ASPIRE_BRANDING;

      case 'abnic':
        return ABNIC_BRANDING;
    }
  }
}
