import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BrandingService } from '@feature/branding';
import { EnvironmentService } from '@util/environment';
import { CookiesService } from '../../cookies.service';

@Component({
  selector: 'feature-cookies-overlay',
  templateUrl: './cookies-overlay.component.html',
  styleUrls: ['./cookies-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesOverlayComponent {
  readonly showOverlay$ = this.cookiesService.showOverlay$;

  constructor(
    private readonly cookiesService: CookiesService,
    private readonly env: EnvironmentService,
    private readonly brandingService: BrandingService,
  ) {}

  close(): void {
    this.cookiesService.closeOverlay();
  }

  manageCookies(): void {
    this.cookiesService.openManageCookiesDialog();
  }

  openTermsAndConditions(): void {
    window.open(this.brandingService.branding().termsAndConditionsURL);
  }

  openPrivacyStatement(): void {
    window.open(this.brandingService.branding().privacyStatementURL);
  }
}
