import { HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UISnackbarService } from '@ui/components';
import { i18nLoaderFactory } from './i18n-loader.factory';
import { I18nLazyLoadConfiguration } from './interfaces';

export const I18N_LAZY_LOAD_CONFIGURATION = new InjectionToken<I18nLazyLoadConfiguration>(
  'i18n-lazy-load-configuration',
);

/**
 * Use this when lazy-loading an additional translation file for a lazy-loaded module. A few notes on this:
 * - Due to bug https://github.com/ngx-translate/core/issues/1193 we can not extend the main translations. This is why
 *   we load all translations again in a lazy-loaded module, though caching of the translation file requests ensures
 *   this will not hamper speed.
 *   - We also need to provide the UISnackbarService. We can revert this and revert adding the snackbar state service too,
 *     when / if translations are extended instead of isolated.
 * - Make sure this is the last file in the modules 'imports' array, in case other modules are exported including their
 *   translation modules.
 * - Make sure the module is instantiated outside of the decorator due to bug
 *   https://github.com/ngx-translate/core/issues/878 breaking the AoT compilation.
 */
@NgModule({
  exports: [TranslateModule],
  imports: [
    TranslateModule.forChild({
      defaultLanguage: 'en',
      useDefaultLang: true,
      isolate: true,
      loader: {
        provide: TranslateLoader,
        useFactory: i18nLoaderFactory,
        deps: [HttpBackend, I18N_LAZY_LOAD_CONFIGURATION],
      },
    }),
  ],
  providers: [UISnackbarService, provideHttpClient(withInterceptorsFromDi())],
})
export class I18nLazyLoadModule {
  constructor(translateService: TranslateService) {
    if (!translateService.currentLang && translateService.defaultLang) {
      translateService.use(translateService.defaultLang);
    }
  }

  static withPath(...i18nLazyLoadConfiguration: I18nLazyLoadConfiguration[]): ModuleWithProviders<I18nLazyLoadModule> {
    return {
      ngModule: I18nLazyLoadModule,
      providers: [{ provide: I18N_LAZY_LOAD_CONFIGURATION, useValue: i18nLazyLoadConfiguration }],
    };
  }
}
