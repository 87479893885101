import { ChangeDetectionStrategy, Component, signal, WritableSignal } from '@angular/core';
import { UIControlValueAccessorBase, uiValueAccessorProvider } from '../../../form';

@Component({
  selector: 'ui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [uiValueAccessorProvider(UICheckboxComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UICheckboxComponent extends UIControlValueAccessorBase<boolean> {
  readonly checked: WritableSignal<boolean> = signal(false);
  readonly disabled: WritableSignal<boolean> = signal(false);

  constructor() {
    super();
  }

  writeValue(checked: boolean): void {
    this.checked.set(checked);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  buttonClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.onModelChange(!this.checked());
  }

  private onModelChange(value: boolean): void {
    if (this.disabled()) {
      return;
    }

    this.checked.set(value);

    if (this.onChangeFn) {
      this.onChangeFn(value);
    }
  }
}
