import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UIIconComponent } from './icon.component';
import { UIIcon } from './icon.enum';

@NgModule({
  declarations: [UIIconComponent],
  exports: [UIIconComponent],
  imports: [CommonModule, MatIconModule],
  providers: [MatIconRegistry, provideHttpClient(withInterceptorsFromDi())],
})
export class UIIconModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.registerIcons();
  }

  private registerIcons(): void {
    Object.values(UIIcon).forEach((icon: UIIcon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${icon}.svg`),
      );
    });
  }
}
