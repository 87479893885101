<div
  class="app-bar"
  [class.app-bar--with-back-button]="backButton.children.length"
  [class.app-bar--with-title]="title.children.length"
>
  <div class="app-bar__title">
    <div #backButton>
      <ng-content select="[ui-app-bar-back-button]" />
    </div>
    <img class="app-bar__logo-small" brandingAssetSrc="icon/app-bar-icon.svg" alt="logo-small" (click)="alertEnv()" />
    <div #title class="app-bar__text"><ng-content select="[ui-app-bar-title]" /></div>
  </div>
  <div class="app-bar__icons">
    <ng-content select="[ui-app-bar-button]" />
  </div>
</div>
