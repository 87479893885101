import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { UIButtonModule, UIDialogModule, UIToggleModule } from '@ui/components';
import { I18nLazyLoadModule } from '@util/i18n';
import { CookiesDialogComponent } from './cookies/components/cookies-dialog/cookies-dialog.component';
import { CookiesOverlayComponent } from './cookies/components/cookies-overlay/cookies-overlay.component';

const TRANSLATE_MODULE: ModuleWithProviders<I18nLazyLoadModule> = I18nLazyLoadModule.withPath({
  filePath: 'libs/feature/analytics',
  fileName: 'analytics',
});

@NgModule({
  declarations: [CookiesDialogComponent, CookiesOverlayComponent],
  imports: [
    CommonModule,
    OverlayModule,
    UIButtonModule,
    MatDialogModule,
    UIDialogModule,
    FormsModule,
    UIToggleModule,
    TRANSLATE_MODULE,
  ],
  exports: [CookiesOverlayComponent],
})
export class AnalyticsModule {}
