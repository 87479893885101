import { ChangeDetectionStrategy, Component, Input, signal, WritableSignal } from '@angular/core';
import { UIControlValueAccessorBase, uiValueAccessorProvider } from '../../../form';

@Component({
  selector: 'ui-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [uiValueAccessorProvider(UIToggleComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIToggleComponent extends UIControlValueAccessorBase<boolean> {
  readonly toggled: WritableSignal<boolean> = signal(false);
  @Input() disabled: boolean = false;

  override writeValue(toggled: boolean): void {
    this.toggled.set(toggled);
  }

  toggle(): void {
    this.writeValue(!this.toggled());
    if (this.onChangeFn) {
      this.onChangeFn(this.toggled());
    }
  }
}
