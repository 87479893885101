import { Branding } from '../../branding.interface';

export const ABNIC_BRANDING: Branding = {
  termsAndConditionsURL: 'https://abnichealthcare.com/terms-of-use-for-payer-and-provider',
  privacyStatementURL: 'https://abnichealthcare.com/privacy-statement',
  pageTitle: 'Abnic Health Care | Powered by CarePay',
  support: 'url',
  supportURL: 'https://abnichealthcare.com/',
  healthTab: false,
  providerTab: false,
};
