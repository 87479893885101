import { registerLocaleData } from '@angular/common';
import { HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeAe from '@angular/common/locales/en-AE';
import { NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { i18nLoaderFactory } from './i18n-loader.factory';
import { I18nMissingTranslationHandler } from './missing-translation-handler';

/**
 * Use this only once in app.module.ts to initialize translations.
 */
@NgModule({
  exports: [TranslateModule],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: i18nLoaderFactory,
        deps: [HttpBackend],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: I18nMissingTranslationHandler,
      },
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class I18nRootModule {
  constructor(translateService: TranslateService) {
    I18nRootModule.setLanguage(translateService);
    // Loading this dynamically using dynamic import doesn't work,
    // so hardcoding en-AE for now.
    registerLocaleData(localeAe, 'en-AE');
  }

  private static setLanguage(translate: TranslateService): void {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
