<div class="dialog">
  <div mat-dialog-content>
    <h3 class="dialog__title">
      <ng-content select="[title]" />
    </h3>
    <div class="mb-4 mt-3 dialog__description">
      <ng-content select="[description]" />
    </div>
    <div class="d-flex">
      <div class="mt-2 me-4">
        <ng-content select="[primary-action]" />
      </div>
      <div class="ml-4 mt-2">
        <ng-content select="[secondary-action]" />
      </div>
    </div>
  </div>
</div>
