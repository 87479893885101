import { Environment } from '@util/environment';
import { BrandingColorMap } from './branding-color-map.interface';
import { BRANDING_COLOR_MAP_ABNIC } from './brands/abnic/brand-colors';
import { BRANDING_COLOR_MAP_ASPIRE } from './brands/aspire/brand-colors';
import { BRANDING_COLOR_MAP_MTIBA } from './brands/mtiba/brand-colors';

export const BRANDING_COLOR_MAPS: Record<Environment['branding'], BrandingColorMap> = {
  mtiba: BRANDING_COLOR_MAP_MTIBA,
  aspire: BRANDING_COLOR_MAP_ASPIRE,
  abnic: BRANDING_COLOR_MAP_ABNIC,
};
