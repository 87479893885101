import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ui-step',
  templateUrl: './step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIStepComponent {
  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<unknown>;
}
