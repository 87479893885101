import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgControl, Validators } from '@angular/forms';
import { EnvironmentService } from '@util/environment';
import { countryCodes, CountryISOCodes } from '@util/phonevalidation';
import { Subject, takeUntil } from 'rxjs';
import { UIControlValueAccessorBase, uiValueAccessorProvider } from '../../form';
import { UI_INVALID_PHONE_NUMBER_ERROR } from '../constants/invalid-phone-number-error.constant';
import { UIPhoneNumberService } from '../services/phone-number.service';
import { uiPhoneNumberValidator } from '../validators/phone-number.validator';

@Component({
  selector: 'ui-phone-number',
  templateUrl: './phone-number.component.html',
  providers: [UIPhoneNumberService, uiValueAccessorProvider(UIPhoneNumberComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIPhoneNumberComponent extends UIControlValueAccessorBase<string> implements OnInit, OnDestroy {
  readonly INVALID_PHONE_ERROR: string = UI_INVALID_PHONE_NUMBER_ERROR;
  readonly countryCodes: WritableSignal<Array<{ code: string; countryISOCode: CountryISOCodes }>> = signal([]);
  readonly phoneNumberForm = new FormGroup(
    {
      countryCode: new FormControl<string>(null, {
        nonNullable: false,
      }),
      nationalNumber: new FormControl<string>(null, {
        nonNullable: false,
      }),
    },
    {
      validators: [uiPhoneNumberValidator('countryCode', 'nationalNumber')],
    },
  );

  private readonly destroy$: Subject<void> = new Subject();

  get countryCode(): FormControl<string | null> {
    return this.phoneNumberForm.controls.countryCode;
  }

  get nationalNumber(): FormControl<string | null> {
    return this.phoneNumberForm.controls.nationalNumber;
  }

  get countryISOCode(): string {
    return this.countryCodes().find((country) => country.code === this.countryCode.value)?.countryISOCode;
  }

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private readonly formGroupDirective: FormGroupDirective,
    private readonly phonevalidationService: UIPhoneNumberService,
    private readonly environment: EnvironmentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeValidators();

    this.subscribeToValueChanges();
    this.subscribeToOnSubmit();
    this.setCountries();
    this.setDefaultCountryCode();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    this.phoneNumberForm.markAllAsTouched();
    this.formGroupDirective.form.markAllAsTouched();

    this.phoneNumberForm.updateValueAndValidity();
    this.formGroupDirective.form.updateValueAndValidity();
  }

  writeValue(phoneValue: string): void {
    if (phoneValue) {
      const countryCode: string = this.phonevalidationService.extractCountryCode(phoneValue);
      const nationalNumber: string = this.phonevalidationService.extractNationalNumber(phoneValue);

      this.phoneNumberForm.patchValue({
        countryCode: countryCode ?? null,
        nationalNumber: nationalNumber ?? null,
      });
    }
  }

  private setCountries(): void {
    for (const [country, code] of Object.entries(countryCodes)) {
      if (this.environment.environment.phoneNumberAllowCountries.includes(country)) {
        this.countryCodes.update((currentCountryCodes) => [
          ...currentCountryCodes,
          { code: code, countryISOCode: country as CountryISOCodes },
        ]);
      }
    }
  }

  private setDefaultCountryCode(): void {
    this.phoneNumberForm.patchValue({
      countryCode: countryCodes[this.environment.environment.phoneNumberDefaultCountry as CountryISOCodes],
    });
  }

  private initializeValidators(): void {
    const ngControl = this.injector.get(NgControl);
    if (ngControl.control.hasValidator(Validators.required)) {
      this.phoneNumberForm.controls.countryCode.addValidators([Validators.required]);
      this.phoneNumberForm.controls.nationalNumber.addValidators([Validators.required]);
    }
  }

  private subscribeToValueChanges(): void {
    this.phoneNumberForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((newValue: Partial<{ countryCode: string; nationalNumber: string }>) => {
        const phoneValue: string = newValue.countryCode + newValue.nationalNumber;
        const phoneNumber: string | null = phoneValue && this.phonevalidationService.convertToPhoneNumber(phoneValue);

        this.onChangeFn(phoneNumber);
      });
  }

  private subscribeToOnSubmit(): void {
    this.formGroupDirective.ngSubmit.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.phoneNumberForm.markAllAsTouched();
      this.phoneNumberForm.updateValueAndValidity();
    });
  }
}
