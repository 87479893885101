import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { BrandingService } from './branding.service';

/*
 * Use this directive to set the src attribute of an image to the branded version of the image.
 * Example: <img brandingAssetSrc="icon/home-no-results.svg" /> will load the image from:
 * `/assets/brands/{brandName}/assets/icon/home-no-results.svg`
 */
@Directive({
  selector: '[brandingAssetSrc]',
})
export class BrandingAssetSrcDirective implements OnChanges {
  @Input() brandingAssetSrc: string;

  constructor(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly _elRef: ElementRef,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly _renderer: Renderer2,
    private readonly brandingService: BrandingService,
  ) {}

  ngOnChanges(): void {
    const srcUrl: string = `/assets/brands/${this.brandingService.brand()}/assets/${this.brandingAssetSrc}`;
    this._renderer.setAttribute(this._elRef.nativeElement, 'src', srcUrl);
  }
}
