import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { filter, Subject, takeUntil } from 'rxjs';
import { UIControlValueAccessorBase, uiValueAccessorProvider } from '../../../form';
import { UIFileData } from '../../interfaces/file-data.interface';
import { UINecessaryFileType } from '../../interfaces/necessary-file-type.interface';
import { uiMissingFilesValidation } from '../../validators/file-validators';

const FILE_LIMIT_SIZE: number = 10 * 1024 * 1024; // 10 MB

@Component({
  selector: 'ui-select-files',
  templateUrl: './select-files.component.html',
  styleUrls: ['./select-files.component.scss'],
  providers: [uiValueAccessorProvider(UISelectFilesComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UISelectFilesComponent<FileType>
  extends UIControlValueAccessorBase<Array<UIFileData<FileType>>>
  implements OnInit, OnDestroy
{
  @Input() fileTypes: Array<{ value: FileType; label: string }>;
  @Input() necessaryFilesTypes: Array<{ value: UINecessaryFileType<FileType>; label: string }> = [];

  filesForm!: FormGroup;

  readonly filesForUpload: WritableSignal<Array<File>> = signal([]);
  readonly filesToBigForUpload: WritableSignal<Array<File>> = signal([]);

  get fileTypesControl(): FormArray<FormControl<FileType>> {
    return this.filesForm.controls['fileTypes'] as FormArray<FormControl<FileType>>;
  }

  get hasNoFileUploadedError(): boolean {
    return this.filesForm.touched && this.filesForm.hasError('noFilesUploaded');
  }

  get hasMissingFileError(): boolean {
    return this.filesForm.touched && this.filesForm.hasError('missingFileType');
  }

  get hasExceedsRequiredAmountError(): boolean {
    return this.filesForm.touched && this.filesForm.hasError('exceedsRequiredAmount');
  }

  private readonly destroy$: Subject<void> = new Subject();

  constructor(private readonly formGroupDirective: FormGroupDirective) {
    super();
  }

  ngOnInit(): void {
    this.initializeValidator();

    this.subscribeToValueChanges();
    this.subscribeToOnSubmit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasFileTypeError(index: number): boolean {
    const formControl: FormControl<FileType | string | null> = this.fileTypesControl.at(index);
    return formControl.touched && !formControl.valid;
  }

  onSubmit(): void {
    this.filesForm.markAllAsTouched();
    this.formGroupDirective.form.markAllAsTouched();

    this.filesForm.updateValueAndValidity();
    this.formGroupDirective.form.updateValueAndValidity();
  }

  onFileSelected(files: FileList | null): void {
    if (!files) {
      return;
    }
    Array.from(files).forEach((file: File) => {
      if (file.size < FILE_LIMIT_SIZE) {
        this.fileTypesControl.push(
          new FormControl<FileType>('' as unknown as FileType, { validators: [Validators.required] }),
        );
        this.filesForUpload.update((files: File[]) => [...files, file]);
      } else {
        this.filesToBigForUpload.update((files: File[]) => [...files, file]);
      }
    });
  }

  onDeleteFile(index: number): void {
    this.filesForUpload.update((files: File[]) => files.filter((value: File, i: number) => i !== index));
    this.fileTypesControl.removeAt(index);
  }

  onDeleteFileToBigForUpload(index: number): void {
    this.filesToBigForUpload.update((files: File[]) => files.filter((value: File, i: number) => i !== index));
  }

  writeValue(files: Array<UIFileData<FileType>>): void {
    if (files) {
      this.filesForUpload.set(files.map((file: UIFileData<FileType>) => file.file));
      this.filesForm.patchValue(files.map((file: UIFileData<FileType>) => file.type));
    }
  }

  private initializeValidator(): void {
    this.filesForm = new FormGroup({ fileTypes: new FormArray<FormControl<FileType>>([]) });

    this.filesForm.setValidators(
      uiMissingFilesValidation<FileType>(
        this.necessaryFilesTypes.map((type) => type.value),
        'fileTypes',
      ),
    );
  }

  private subscribeToValueChanges(): void {
    this.filesForm.valueChanges
      .pipe(
        filter(() => !!this.onChangeFn),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.onChangeFn(this.filesForm.valid ? this.mapToFileData() : null);
      });
  }

  private subscribeToOnSubmit(): void {
    this.formGroupDirective.ngSubmit.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filesForm.markAllAsTouched();
      this.filesForm.updateValueAndValidity();
    });
  }

  private mapToFileData(): Array<UIFileData<FileType>> {
    let files: Array<UIFileData<FileType>> = [];
    for (let i: number = 0; i < this.filesForUpload().length; i++) {
      const file: File = this.filesForUpload()[i];
      const type: FileType = this.fileTypesControl.at(i).value as FileType;
      files = [...files, { file, type }];
    }
    return files;
  }
}
