import { Branding } from '../../branding.interface';

export const MTIBA_BRANDING: Branding = {
  termsAndConditionsURL: 'https://mtiba.com/terms',
  privacyStatementURL: 'https://mtiba.com/privacy-policy',
  pageTitle: 'MTIBA Member | Powered by CarePay',
  support: 'freshdesk',
  supportFreshworksURL: 'https://widget.freshworks.com/widgets',
  supportFreshdeskFormId: 35000000625,
  supportFreshdeskWidgetId: 35000001954,
  healthTab: false,
  providerTab: false,
};
