import { ChangeDetectionStrategy, Component, ContentChildren, QueryList, signal, WritableSignal } from '@angular/core';
import { UIStepComponent } from '../step/step.component';

@Component({
  selector: 'ui-stepper',
  styleUrls: ['./stepper.component.scss'],
  templateUrl: './stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIStepperComponent {
  readonly currentStep: WritableSignal<number> = signal(0);

  @ContentChildren(UIStepComponent)
  readonly steps: QueryList<UIStepComponent>;

  nextStep(): void {
    this.currentStep.update((step: number) => step + 1);
  }

  previousStep(): void {
    this.currentStep.update((step: number) => step - 1);
  }
}
